<template>
  <el-dialog
          title="验证手机"
          :visible.sync="dialogVisible"
          @close="$emit('close')"
          width="30%"
  >
    <el-form ref="verifyPhoneForm" :rules="rules" :model="model" label-width="80px" id="verifyPhoneForm">


      <el-form-item label="手机号">

        <span class="mr10">{{phone}}</span>
        <el-button size="small" type="info" @click="sendPhone" v-show="intervalTime<1" :loading="phoneSending"
                   :disabled="phoneSending">发送短信
        </el-button>
        <el-button size="small" type="info" v-show="intervalTime>0" disabled>{{intervalTime}}秒后可重发</el-button>

      </el-form-item>

      <slot>

      </slot>

      <el-form-item>
        <el-button type="primary" :loading="submitting" @click="submit('verifyPhoneForm')">{{buttonText}}
        </el-button>
      </el-form-item>


    </el-form>

  </el-dialog>
</template>

<script>
  export default {
    name: "VerifyPhoneDialog",
    data () {
      return {
        dialogVisible: false,
        isSent: false,
        intervalTime: 0,
        intervalJob: null,
        phoneSending: false,
        submitting: false
      }
    },
    props: {
      visible: Boolean,
      phone: String,
      rules: Object,
      model: Object,
      buttonText: String,
      sendPhoneUrl: String,
      submitUrl: String
    },
    computed: {
      mailServer () {
        if (!this.phone) {
          return ''
        }
        const arr = this.phone.split('@')
        return arr.length === 2 ? 'http://mail.' + arr[1] : '';
      }
    },
    watch: {
      visible (val) {
        this.dialogVisible = val
      }
    },
    methods: {
      show () {
        this.visible = true
      },
      submit (formName) {
        const self = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (self.submitting) {
              return
            }
            self.submitting = true
            this.$http.post({
              url: self.submitUrl,
              data: self.model,
              success () {
                self.$emit('success')
              },
              always () {
                self.submitting = false
              }
            })
          } else {
            return false
          }
        });
      },
      sendPhone () {
        const self = this
        if (self.intervalTime > 0 || self.phoneSending) {
          return
        }
        self.phoneSending = true
        this.$http.post({
          url: self.sendPhoneUrl,
          data: {
            phone: self.phone
          },
          success () {
            self.isSent = true
            self.startIntervalJob()
          },
          always () {
            self.phoneSending = false
          }
        })
      },
      startIntervalJob () {
        const self = this
        self.intervalTime = 60
        self.intervalJob = setInterval(function () {
          if (--self.intervalTime < 1) {
            self.stopIntervalJob()
          }
        }, 1000)
      }
      ,
      stopIntervalJob () {
        if (this.intervalJob !== null) {
          clearInterval(this.intervalJob)
        }
      }
    },
    destroyed () {
      this.stopIntervalJob()
    }
  }
</script>

<style scoped>

</style>