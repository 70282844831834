<template>
  <verify-phone-dialog :rules="rules"
                       :model="model"
                       :visible="visible"
                       :phone="phone"
                       @close="$emit('update:visible', false)"
                       button-text="完成注册"
                       send-phone-url="/auth/sendCodeForRegister.action"
                       submit-url="/auth/register.action"
                       @success="success"
  >

    <el-form-item prop="verifyCode" label="验证码">
      <el-input v-model="model.verifyCode"
                placeholder="请输入短信验证码"
                type="text"
                style="width: 200px"
      />
    </el-form-item>


  </verify-phone-dialog>
</template>

<script>
  import VerifyPhoneDialog from './VerifyPhoneDialog'

  export default {
    name: "VerifyRegisterPhone",
    components: {VerifyPhoneDialog},
    data () {
      return {
        model: {
          verifyCode: '',
          inviteCode: '',
          loginPassword: '',
          userName: ''
        },
        rules: {
          verifyCode: [
            {required: true, message: '请输入验证码', 'trigger': 'blur'},
            {min: 6, max: 6, message: '验证码错误', 'trigger': 'blur'}
          ]
        }
      }
    },
    props: {
      phone: String,
      visible: Boolean
    },
    methods: {
      setFormData (data) {
        this.model.inviteCode = data.inviteCode
        this.model.loginPassword = data.password
        this.model.userName = data.userName
        this.model.phone = data.phone
      },
      success () {
        this.$message.success('注册成功')
        this.$router.push({name: 'memberIndex'})
      }
    }
  }
</script>

<style scoped>

</style>