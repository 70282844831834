<template>
  <div class="panel login-panel">
    <div class="panel-head">{{title}}</div>
    <div class="panel-body">
      <el-form method="post" :rules="rules" :model="model" ref="registerForm"
               id="registerForm"
               onsubmit="return false" label-width="80px">

        <el-form-item prop="userName" label="姓名">
          <el-input v-model="model.userName" placeholder="怎么称呼您？"></el-input>
        </el-form-item>

        <el-form-item prop="phone" label="手机号">
          <el-input v-model="model.phone" placeholder="使用手机号作为登录账号"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="loginPassword">
          <el-input v-model="model.loginPassword" type="password" placeholder="8至20位英文、数字、特殊字符组成"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input v-model="model.confirmPassword" type="password" placeholder="请再次输入登录密码"></el-input>
        </el-form-item>

        <el-form-item label="验证码" prop="_captcha">
          <el-input :maxlength="4" placeholder="右图中的字符" v-model="model._captcha" style="width: 120px"></el-input>
          <captcha-image ref="captcha-image"/>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" :loading="locked" @click="submitForm('registerForm')">立即注册</el-button>
          <router-link :to="{name:'authLogin'}" class="pull-right">已有账号？立即登录</router-link>
        </el-form-item>

        <div class="clear"></div>
      </el-form>

    </div>
    <verify-register-phone
            ref="verify-phone"
            :visible.sync="dialogVisible"
            :phone="model.phone"
    />
  </div>
</template>
<script>
  import CaptchaImage from '../common/CaptchaImage'
  import VerifyRegisterPhone from './VerifyRegisterPhone'

  export default {
    name: 'AuthRegister',
    components: {VerifyRegisterPhone, CaptchaImage},
    data: function () {
      const self = this
      return {
        title: '账号注册',
        dialogVisible: false,
        model: {
          phone: '',
          loginPassword: '',
          confirmPassword: '',
          userName: '',
          _captcha: ''
        },
        locked: false,
        rules: {
          userName: [
            {required: true, message: '请输入您的姓名', 'trigger': 'blur'},
            {max: 36, message: '邀请码长度超过限制', 'trigger': 'blur'}
          ],
          phone: [
            {required: true, message: '请输入邮箱地址', 'trigger': 'blur'},
            {
              validator (rule, value, callback) {
                if (!value.match(/^1[3456789]\d{9}$/)) {
                  callback(new Error('手机号格式错误'))
                } else {
                  callback()
                }
              },
              'trigger': 'blur'
            }
          ],
          loginPassword: [
            {required: true, message: '请输入登录密码', 'trigger': 'blur'},
            {min: 8, max: 20, message: '密码长度范围为8-20个字符', 'trigger': 'blur'}
          ],
          confirmPassword: [
            {required: true, message: '请再次输入登录密码', 'trigger': 'blur'},
            {
              validator (rule, value, callback) {
                if (value !== self.model.loginPassword) {
                  callback(new Error('两次密码输入不一致'))
                } else {
                  callback()
                }
              },
              'trigger': 'blur'
            }
          ],
          _captcha: [
            {required: true, message: '请输入图片中的验证码', 'trigger': 'blur'}
          ]
        }
      }
    },
    methods: {
      submitForm (formName) {
        const self = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            self.locked = true
            self.$http.post({
              url: '/auth/checkRegister.action',
              data: {
                phone: self.model.phone,
                userName: self.model.userName,
                _captcha: self.model._captcha
              },
              fail (response) {
                self.$refs['captcha-image'].changeImg()
                self.$http.errorHandle(response)
              },
              success () {
                self.$refs['verify-phone'].setFormData({
                  userName: self.model.userName,
                  password: self.model.loginPassword,
                  phone: self.model.phone
                })
                self.dialogVisible = true
              },
              always () {
                self.locked = false
              }
            })
          } else {
            return false;
          }
        })
      }
    }
  }
</script>
<style scoped>
  .login-panel {
    height: 440px;
    margin-top: -220px;
  }


</style>
